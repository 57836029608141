.ol-popup {
  display: none;
  position: absolute;
  background-color: white;
  padding: 0px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  border: 5px solid #0ba500;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-content {
  min-width: 280px;
  /* max-height: 200px; */
  overflow-x: auto;
}

/* .ol-popup-closer {
      position: absolute;
      top: 2px;
      right: 2px;
       font-size: 150%;
      padding: 0 4px;
      color: gray;
      text-decoration: none;
  } */

.ol-popup-closer {
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 100%;
  /* padding: 10px; */
  color: white;
  text-decoration: none;
  background-color: red;
  width: 20px;
  height: 20px;
  text-align: center;
}

.ol-popup-closer:after {
  content: "\2715";
  color: white;
}
