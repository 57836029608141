.bgHeader {
  background: #6433D7 !important ;
}

.customHeaterPadding {
  padding: "0px 0px 0px 30px" !important;
  width: 100%;
}

.applicationName {
  font-size: large !important;
  font-weight: bold;
  color: white;
  margin-left: 20px;
  padding-top: 20px;
}

.burggerButtonStyle {
  float: left !important;
  cursor: pointer !important;
  color: #ffffff !important;
  font-weight: bold !important;
  font-size: 30 !important;
}

.logoutIconStyle {
  /* float: right !important; */
  margin-right: 20px !important;

  font-size: 30 !important;
  cursor: pointer;
}
.logoutIconStyle a {
  color: white !important;
}
.cusomMargin {
  margin-left: 40px;
}

.logoutInlineStyle {
  color: rgb(250, 250, 250) !important;
  font-size: 30;
  margin-right: 20px;
}

.bottomHeaderStyle {
  height: 100px;
  background: #6433D7;
}

.ant-layout .ant-layout-header {
  padding-inline: 5px !important;
}

@media screen and (max-width: 600px) {
  .ant-layout .ant-layout-header {
    height: 80px !important;
  }
}
