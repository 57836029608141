.zoom {
  position: absolute;
  bottom: 5%;
  right: 2%;
  display: flex;
  flex-direction: column;
}

.inputradio {
  transform: scale(1.5);
  accent-color: #0098a7;
}

.extentZoom {
  position: absolute;
  bottom: 12.3%;
  right: 2%;
  display: flex;
  color: yellow;
  font-weight: 700;
  flex-direction: column;
}

.waterDetails {
  position: absolute;
  top   : 2.3%;
  left: 2%;
  display: flex;
  color: yellow;
  font-weight: 700;
  flex-direction: column;
}

.MousePosition {
  position: absolute;
  bottom: 3%;
  right: 7%;
  display: flex;
  color: yellow;
  font-weight: 700;
  flex-direction: column;
}

.ol-control button {
  background-color: green;
  color: white !important;
}

.ol-control {
  position: absolute;
  background-color: unset;
  border-radius: 44px;
  padding: 0px !important;
}

.draw-map:-webkit-full-screen {
  height: 100%;
  margin: 0;
}
.draw-map:fullscreen {
  height: 100%;
}
.draw-map .ol-rotate {
  top: 3em;
}

/* input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
} */

/* input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #0098a7;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
} */

.ol-layer{
  position: fixed !important;
}


.ant-tree {
background-color: rgba(0, 0, 0, 0) !important;
}