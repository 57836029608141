.spinStyle {
    width: 40px !important;
  height: 40px !important;
  /* background-color: #0CB1C4; */
  animation-name: spin1;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  color: #673ab7;
}

@keyframes spin1 {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.ant-picker-clear{
    display: none !important;
}