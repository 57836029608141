body {
  margin: 0;
  font-feature-settings: "liga", "kern";
  color: #caccd5;
  /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  scroll-behavior: auto;

  font-family: customFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: customFont;

  src: url(https://fonts.googleapis.com/css?family=Inter);
}

[class*=" ant-layout"] {
  font-family: customFont !important;
}

.ant-select-selector {
  width: 100%;
  /* height: 30px !important; */
  padding: 0 10px !important;
  box-shadow: none !important
}

/* .ant-select .ant-select-arrow {
  top :25% !important
} */

.ant-picker {
  position: relative;
  background-color: #ffffff;
  box-shadow: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
  /* border-top: none !important;
  border-left: none !important;
  border-right: none !important; */
  border-radius: 0px !important;
  border-color: #32be8f !important;
}

.ant-select-selector {
  position: relative;
  background-color: #ffffff;
  /* border-bottom: 1px solid #d9d9d9 !important; */
  /* border-top: none !important;
  border-left: none !important;
  border-right: none !important; */
  border-radius: 0px !important;
  border-color: #32be8f !important;


  /* transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1); */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content {
  flex: none !important;
}

.ant-menu-inline .ant-menu-item {
  height: 55px !important;
  border-radius: 0px !important;
}

.ant-menu-light .ant-menu-item-selected {
  color: white !important;
}

.ant-menu-light .ant-menu-item-selected {
  background: #6433D7 !important;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  color: white !important;
  background: #9B59B6 !important;
}

.ant-table-wrapper .ant-table-column-sorter {
  color: white !important;
}

.ant-table-wrapper .ant-table-filter-trigger {
  color: white !important;
}

.ant-dropdown .ant-table-filter-dropdown .ant-checkbox-wrapper+span {
  color: white !important;
}

.ant-dropdown .ant-dropdown-menu {
  background: #6433D7 !important;
  border-radius: 0px !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  color: #1677ff !important;
  background-color: #38075f !important;
}

.ant-btn-primary {
  color: white !important;
  background: #6433D7 !important;
  /* box-shadow: 0 2px 0 rgb(5 145 255 / 10%) */
}

.ant-btn-link {
  background: #6433D7 !important;
  color: white !important;
}

.ant-btn-default {
  background: #6433D7 !important;
  color: white !important;
}

.ant-switch.ant-switch-checked {
  background: #6433D7 !important;
}

.ant-picker-dropdown .ant-picker-today-btn {
  color: #6433D7 !important;
  margin-right: 10px !important;
  font-weight: bold !important;
  /* background: #6433D7 !important; */
}

.ant-select-multiple .ant-select-selector {
  padding: 5px 24px !important;
}

.ant-segmented .ant-segmented-item-selected {
  background-color: #6433D7 !important;
  /* box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%),
    0 2px 4px 0 rgb(0 0 0 / 2%) !important; */
  color: white !important;
}

.ant-cascader-dropdown .ant-cascader-menu {
  height: 325px !important;

  background: #6433D7 !important;

  color: white !important;
}

.ant-cascader-dropdown .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
  background-color: #38075f !important;
}

.ant-cascader-dropdown .ant-cascader-menu-item-disabled {
  color: #f0f0f0 !important;
}

.ant-empty-normal {
  margin-block: 32px;
  color: rgb(15, 3, 3) !important;
}

.ant-select-dropdown {
  background-color: #6433D7 !important;
  color: white !important;
}

.ant-select-dropdown .ant-select-item {
  color: white !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #38075f !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content {
  flex: none !important;
}

.ant-menu-inline .ant-menu-item {
  height: 55px !important;
  border-radius: 0px !important;
}

.ant-menu-light .ant-menu-item-selected {
  color: white !important;
}

.ant-menu-light .ant-menu-item-selected {
  background: #6433D7 !important;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  color: white !important;
  background: #6433D7 !important;
  height: 50px !important;
}

/* .ant-table-wrapper table { text-align: center !important; } */


.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
  border-start-end-radius: 0px !important;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  border-start-start-radius: 0px !important;
}

.ant-table-wrapper .ant-table-column-sorter {
  color: white !important;
}

.ant-table-wrapper .ant-table-filter-trigger {
  color: white !important;
}

.ant-dropdown .ant-table-filter-dropdown .ant-checkbox-wrapper+span {
  color: white !important;
}

.ant-dropdown .ant-dropdown-menu {
  background: #6433D7 !important;
  border-radius: 0px !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  color: #1677ff !important;
  background-color: #38075f !important;
}

.ant-btn-primary {
  color: white !important;
  background: #6433D7 !important;
  /* box-shadow: 0 2px 0 rgb(5 145 255 / 10%) */
}

.ant-btn-link {
  background: #6433D7 !important;
  color: white !important;
}

.ant-btn-default {
  background: #6433D7 !important;
  color: white !important;
}

.ant-switch.ant-switch-checked {
  background: #6433D7 !important;
}

.ant-picker-dropdown .ant-picker-today-btn {
  color: #6433D7 !important;
  margin-right: 10px !important;
  font-weight: bold !important;
  /* background: #6433D7 !important; */
}

.ant-select-multiple .ant-select-selector {
  padding: 5px 24px !important;
}

.ant-segmented .ant-segmented-item-selected {
  background-color: #6433D7 !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%),
    0 2px 4px 0 rgb(0 0 0 / 2%) !important;
  color: white !important;
}

.ant-cascader-dropdown .ant-cascader-menu {
  height: 325px !important;

  background: #6433D7 !important;

  color: white !important;
}

.ant-cascader-dropdown .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
  background-color: #38075f !important;
}

.ant-cascader-dropdown .ant-cascader-menu-item-disabled {
  color: #f0f0f0 !important;
}

.ant-empty-normal {
  margin-block: 32px;
  color: rgb(15, 3, 3) !important;
}

.ant-select-dropdown {
  background-color: #6433D7 !important;
  color: white !important;
}

.ant-select-dropdown .ant-select-item {
  color: white !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #38075f !important;
}

/* #38075f !important */

.ant-drawer .ant-drawer-body {
  padding: 0px !important;
}

.ant-menu-inline .ant-menu-item {
  margin-inline: 0px !important;
  margin-block: 0px !important;
  width: calc(100% + 1px) !important;
  height: 64px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;

}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6433D7 !important;
  border-radius: 5 px;
}

.ant-layout .ant-layout-sider {

  background: unset !important;

}


.ant-form-vertical .ant-form-item-label {
  padding: 0px !important;
}

.ant-input-lg {
  padding: 0px !important;
}


.ant-spin-nested-loading>div>.ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -50px !important;
}

.reportOkDelete {
  background-color: green !important;
}
.reportCancelDelete {
  background-color: red !important;
}
.excel-design:hover {
  transform: scale(1.1);
}
.excel-design {
  height: 31px;
  width: 40px;
  border: solid 1px #32be8f;
}

.pdf-design:hover {
  transform: scale(1.1);
}
.pdf-design {
  height: 31px;
  width: 40px;
}

.my-option{
  font-family: "Times New Roman", Times, serif !important;
}

.ant-card .ant-card-body{
  padding: 13px !important;
}

#custom-context-menu {
  position: absolute;
  background-color: #000;
  padding-right: 5px;
  border:"1px solid #ffffff";
  display: none;
  width: max-content !important;
}
#custom-context-menu ul {
  list-style: none;
  padding-left: 0; 
  margin: 6px 0 0 0;
}

#custom-context-menu ul li {
  margin-left: 10px; /* Remove any left margin on list items */
}

.ol-popup-content{
  overflow-x: hidden;
}

.apexcharts-legend-text{
  color:white !important;
}