.sidebarRole {
  background: #6433D7 !important ;
  height: 100px;
  padding-top: 20px;
  /* border-bottom: dotted; */
  border-width: 1px;

  color: #fff2f0;
}

.sidebarStyle {
  overflow: auto;
  height: 100vh;
}
.sideTitle {
  top: 0;
  height: 63;
  background: #6433D7;
  padding: 10px;
}
.roleType {
  cursor: pointer;
  color: white;
  font-size: 12;
  text-align: center;
  font-weight: bold;
}

.userName {
    /* padding-top: 6px; */
    cursor: pointer;
    color: white;
    font-size: 16;
    text-align: center;
    font-weight: bold;
}

.menuStyle {
    min-height: 75.7vh
}
